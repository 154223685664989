import React from "react"

import SEO from "../components/seo"
import FullpageSection from "../components/fullpageSection"

const NotFoundPage = () => (
  <main>
    <SEO
      title="Ceres non c'è"
      description="Errore 404: Pagina non trovata"
    />

    <FullpageSection
      normalBackground={true}
      whichTitle={"h1"}
      copyTitle={"Ceres non c'è"}
      copyText={"Ci dispiace ma la pagina che hai cercato non esiste. Prova a tornare in Homepage"}
      backgroundImage={""}
      haveCTA={true}
      buttonLinkTo={"/"}
      buttonLinkLabel={"Homepage"}
    />

  </main>
);

export default NotFoundPage
